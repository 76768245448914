import dayjs from "dayjs";
import { createContext, useState } from "react";

const FilterDataContext = createContext();

const FilterDataProvider = ({ children }) => {
    const [filterData, setFilterData] = useState({
        fromDate: dayjs().format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD')
    });

    return (
        <FilterDataContext.Provider value={{ filterData, setFilterData }}>
            {children}
        </FilterDataContext.Provider >
    );
};

export { FilterDataProvider, FilterDataContext };