import React, { useState, useRef, useEffect, useContext } from 'react';
import { getUsers } from "../../services/TransactionsService";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TableUser from '../../components/table/userTable.jsx'
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Search from '@mui/icons-material/Search.js';
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
import { useMediaQuery } from 'react-responsive';
import { Tab } from '@mui/material';
import { use } from 'i18next';
const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: labelFontSize,
                    fontWeight: 700,

                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& > fieldset > legend": {
                        fontSize: `calc(0.75 * ${labelFontSize})`
                    }
                }
            }
        }
    }
});
const SearchUser = ({ onFilterData }) => {
    const removeValueFromArray = (array, value) => {
        return array.filter(item => item !== value);
    };
    const [checkedVal, setCheckedVal] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [activeSearch, setActiveSearch] = useState(false);
    const handleCheckboxChange = (event) => {
        console.log(event.target.value, event.target.checked)
        console.log(checkedVal)
        event.target.checked ? setCheckedVal([...checkedVal, event.target.value]) : setCheckedVal(removeValueFromArray(checkedVal, event.target.value));
    };
    const handleSearch = () => {
        setActiveSearch(!activeSearch)
    };
    useEffect(() => {
        if (searchVal == '') setActiveSearch(!activeSearch);
    }, [searchVal]);
    useEffect(() => {
        const filters = {};
        console.log(checkedVal)
        if (checkedVal.includes('documents')) filters['documents'] = true;
        if (checkedVal.includes('subaccounts')) filters['subaccounts'] = true;
        if (searchVal != '') filters['virtual_account'] = searchVal;
        onFilterData(filters);
    }, [checkedVal, activeSearch]);
    return (
        <div className='flex gap-6 mx-6 mb-6 mt-2 items-center'>
            <div className=''>
                <FormControl variant="standard">
                    <InputLabel htmlFor="input-with-icon-adornment">
                        Virtual Account
                    </InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        onChange={(e) => {
                            setSearchVal(e.target.value);
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <Search className='cursor-pointer' onClick={handleSearch} />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <div className='flex gap-2'>
                <div className=' mt-4'>
                    <FormControlLabel
                        value="documents"
                        control={<Checkbox onChange={handleCheckboxChange} />}
                        label="Documents"
                    />
                </div>
                <div className='mt-4'>
                    <FormControlLabel
                        value="subaccounts"
                        control={<Checkbox onChange={handleCheckboxChange} />}
                        label="Sub Accounts"
                    />
                </div>
            </div>
        </div>
    );

}
const Profile = () => {
    const { darkMode } = useContext(DarkModeContext); 
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if (isMobile) {
        import("./adminMobile.scss");
    } else {
        import("./userList.scss");
    }
    const [filterData, setFilterData] = useState({});
    const handleFilterData = (data) => {
        console.log(data)
        setFilterData(data);
    };
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <div className="profile">
                    <Sidebar />
                    <div className='w-10/12 sm:w-full'>
                        <Navbar />

                        <div className="h-[calc(100%-112px)] flex flex-col">
                            <SearchUser onFilterData={handleFilterData} />

                            <TableUser filterData={filterData} />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default Profile;
