import React, { lazy, Suspense, useContext } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from 'react-responsive';
import "./home.scss";
import { useTranslation } from 'react-i18next';
import { FilterDataProvider } from '../../context/filterDataContext';

const queryClient = new QueryClient();

const Home = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  if (isMobile) {
    import("./mobileHome.scss");
  } else {
    import("./home.scss");
  }

  // Dynamically import the table based on the device type
  const Table = lazy(() => isMobile
    ? import("../../components/table/Table")
    : import("../../components/table/Table")
  );

  const Widget = lazy(() => isMobile
    ? import("../../components/widget/WidgetMobile")
    : import("../../components/widget/Widget")
  );

  const { t } = useTranslation();

  return (
    <FilterDataProvider>
      <div className="home">
        <Sidebar />
        <div className=" w-10/12 sm:w-full homeContainer">
          <QueryClientProvider client={queryClient}>
            <Navbar />
          </QueryClientProvider>
          <div className="w-full">
            <div className='h-[calc(100%-112px)]'>
              <div className='h-full'>
                <div className=' h-2/3'>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Widget />
                  </Suspense>
                </div>
                <div className="p-0 m-0 sm:p-0 sm:m-0 h-1/3">
                  <div className=" font-semibold mb-1 sm:mb-4 flex justify-center items-center text-center text-xl">{t('Transactions per hour')}</div>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Table graphicToDisplay={'transactionsPerHour'} />
                    </Suspense>
                  </QueryClientProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </FilterDataProvider>
  );
};

export default Home;