import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button, IconButton, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { getStatement } from "../../services/TransactionsService";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
import ReceiptIcon from '@mui/icons-material/Receipt';
import { generatePDF } from "../../components/receipt/Receipt";
import { format } from 'date-fns';
import { useTranslation } from "react-i18next"; // Import useTranslation
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from 'dayjs';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(value);

    return formattedValue;
};

const formatDate = (value) => {
    const date = new Date(value);
    return format(date, 'dd/MM HH:mm');
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'last_updated_at',
        numeric: false,
        disablePadding: false,
        label: 'Hora',
        class: "w-table-22 text-base",
        valueFormatter: ({ value }) => formatDate(value),
    },
    {
        id: 'status_description',
        numeric: false,
        disablePadding: false,
        label: <div className="flex flex-col"><span>Status</span><span>Descrição</span></div>,
        class: "w-table-22",
        cellClassName: 'cellWithStatus',
    },

    {
        id: 'amount_fee',
        numeric: false,
        disablePadding: false,
        label: <div className="flex flex-col"><span>Valor</span><span>Tarifa</span></div>,
        class: "w-table-22",
        cellClassName: 'cellWithStatus',
    },
    {
        id: 'download_receipt',
        numeric: false,
        disablePadding: false,
        label: 'Info',
        class: "w-table-10",
        /* width: 100, */
    },
    /* {
      id: 'movement_type',
      numeric: false,
      disablePadding: false,
      label: 'Cash flow',
      width: 100,
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: 'Amount (R$)',
      width: 150,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      id: 'fee_charged',
      numeric: true,
      disablePadding: false,
      label: 'Fee (R$)',
      width: 150,
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      id: 'account_owner_name',
      numeric: false,
      disablePadding: false,
      label: 'Account Owner Name',
      width: 100,
    },
    {
      id: 'end_to_end_id',
      numeric: false,
      disablePadding: false,
      label: 'End to end ID',
      width: 350,
    },
    {
      id: 'order_id',
      numeric: false,
      disablePadding: false,
      label: 'Order ID',
      width: 350,
    },
    {
      id: 'download_receipt',
      numeric: false,
      disablePadding: true,
      label: 'Download Receipt',
      width: 100,
    }, */
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const { t } = useTranslation();
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={headCell.class}
                        sx={{ padding: '0px',fontSize:'13px' }}
                    >
                        {headCell.label}
                        {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ filterData }) {
    const [open, setOpen] = useState(false);
    const [statementRows, setStatementRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('last_updated_at');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [totalElements, setTotalElements] = React.useState(1);
    const [loading, setLoading] = useState(true); // Introduce loading state
    const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        const fetchStatement = async () => {
            try {
                setLoading(true);
                const response = await getStatement(page + 1, rowsPerPage, filterData);
                /* response.totals.transactions_qty == 0 ? response.totals.transactions_qty = 60 : ''; */
                setTotalElements(response.totals.transactions_qty);
                function statusMessage(status) {
                    if (status == "PAID") {
                        return "Transação confirmada com sucesso.";
                    }
                    else if (status == "FAILED") {
                        return "Falha na confirmação da transação.";
                    }
                    else if (status == "GENERATED") {
                        return "Transação criada, aguardando confirmação.";
                    }
                    else if (status == "REFUNDED") {
                        return "Transação estornada ao beneficiário.";
                    }
                    else if (status == "EXPIRED") {
                        return "Transação expirada. Tente novamente em breve.";
                    }
                }
                const transactions = response?.transactions?.map((t) => ({
                    ...t,
                    key: t.transaction_id,
                    last_updated_at: t?.last_updated_at,
                    end_to_end_id: t?.end_to_end_id,
                    order_id: t?.order_id,
                    status: t?.status,
                    movement_type: t?.movement_type,
                    amount: t?.amount,
                    fee_charged: t?.fee_charged,
                    account_owner_name: t?.account_owner_name,
                    statusMessage: statusMessage(t?.status),
                }));
                setStatementRows(transactions);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setStatementRows([]);
                console.error(error);
                return;
            }
        };
        fetchStatement();
    }, [filterData, page, rowsPerPage]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 15));
        setPage(0);
    };

    const downloadPDF = (transaction) => {
        generatePDF(transaction)
    }

    if (loading) {
        return <div className="text-center mt-24"><CircularProgress color="inherit" /></div>; // Render a loading state while data is being fetched
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const handleOpen = (row) => {
        // console.log(row)
        // console.log(dayjs(row.last_updated_at).format('HH:mm'))

        setModalData(
            <div className="w-full">
                <div id="modal-modal-title" variant="h6" component="h2">
                    <div className="flex flex-col place-content-between gap-2">
                        <div className="flex">
                            <div className="w-full text-sm">Transação: <span className="font-semibold">{row.transaction_id}</span></div>
                        </div>
                        <div className="flex gap-4 text-sm">
                            <div>Data: <span className="font-semibold">{dayjs(row.last_updated_at).format('DD/MM/YYYY')}</span></div>
                            <div>Horário: <span className="font-semibold">{dayjs(row.last_updated_at).format('HH:mm')}</span></div>
                        </div>
                        <div className="flex self-center font-bold text-medium">
                            <div>{row.account_owner_name}</div>
                        </div>
                        <div className="flex">
                            <div className="text-sm">Documento: <span className=" font-semibold">{row.document_number}</span></div>
                        </div>
                    </div>
                </div>
                <div id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="flex flex-col">
                        <div className="flex place-content-between text-sm">
                            <div>Agência: <span className=" font-semibold">{row.account_branch}</span></div>
                            <div>Conta: <span className=" font-semibold">{row.account_number}</span></div>
                        </div>
                        <div className="flex place-content-between text-sm">
                            <div>Descrição: <span className=" font-semibold">{row.movement_type}</span></div>
                            <div>Status: <span className=" font-semibold">{row.status}</span></div>
                        </div>
                        <div className="flex place-content-between text-sm">
                            <div>End to End ID: <span className="font-semibold">{row.end_to_end_id}</span></div>
                        </div>
                        <div className="flex place-content-between text-sm">
                            <div>Valor da taxa:<span className="font-semibold">R$ {row.fee_charged}</span></div>
                            <div>Status da taxa: <span className="font-semibold">{row.fees_status}</span></div>
                        </div>
                    </div>
                    <div className=" mt-2 mb-2 text-center">
                        <IconButton
                            sx={{
                                ':hover': {
                                    bgcolor: 'rgba(0, 0, 0, 0.15)', // theme.palette.primary.main
                                },
                                padding: '0px'
                            }}
                            size="large"
                            disabled={row?.status === 'PAID' ? false : true}
                            onClick={() => downloadPDF(row)}
                            className={
                                row?.status === 'PAID' ? "txgreen" : "cursor-default text-zinc-400"
                            }>

                            <DownloadIcon />
                        </IconButton>
                    </div>
                </div>
            </div>);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setModalData(null);
    };
    return (

        <div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {modalData}
                        {/* <div id="modal-modal-title" variant="h6" component="h2">
              Transação: {row.transaction_id}
            </div>
            <div id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="flex flex-col">
                <div className="flex place-content-between">
                  <div>DATA: {dayjs(row.last_updated_at).format('DD/MM/YYYY')}</div>
                  <div>HORARIO: {dayjs(row.last_updated_at).format('HH:mm')}</div>
                </div>
                <div>E2E ID: XXXXXXX</div>
                <div>TRANSACTION ID: XXXXXXXX</div>
                <div>ORDER ID: XXXXXXXXX</div>
                <div className="flex place-content-between">
                  <div>DESCRICAO: xxxx</div>
                  <div>STATUS: xxxx</div>
                </div>
              </div>
            </div> */}
                    </Box>
                </Modal>
            </div>
            <Paper sx={{ width: '100%', mb: 2 }} className='table'>
                <TableContainer className='table'>
                    <Table
                        sx={{ minWidth: 0, }}
                        aria-labelledby="tableTitle"
                        size="medium"
                        className='table'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            className='table'
                        />
                        <TableBody >
                            {statementRows.map((row, index) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                    selected={false}
                                    sx={{ cursor: 'pointer' }}
                                    className='table' // Apply dark mode class to each row
                                    style={index % 2 ? { background: "rgba(148, 148, 148, 0.3)" } : { background: "white" }}
                                >
                                    <TableCell sx={{ padding: '3px', fontSize: '10px' }} className="tableCell " align="center" >{formatDate(row.last_updated_at)}</TableCell>
                                    <Tooltip title={<Typography fontSize={15}>{row.statusMessage}</Typography>} placement="bottom" arrow>
                                        <TableCell sx={{ padding: '3px', fontSize: '10px' }} className="tableCell flex" align="center" >
                                            <div className="flex flex-col"><span className={`status ${row.status}`}>{row.status}</span><span className={`movement_type ${row.movement_type}`}>{row.movement_type}</span></div>
                                        </TableCell>
                                    </Tooltip>
                                    <TableCell sx={{ padding: '3px', fontSize: '10px' }} className="tableCell text-xsm" align="center" ><div className="flex flex-col"><span>{formatCurrency(row.amount)}</span><span>{formatCurrency(row.fee_charged)}</span></div></TableCell>
                                    <TableCell sx={{ padding: '0px' }} className="tableCell " align="center" ><Button onClick={() => handleOpen(row)} sx={{ padding: '0px',minWidth: 0}}> <AddCircleOutlineIcon fontSize="10px" className=" text-iziblue" /></Button></TableCell>
                                    {/*
                  <TableCell className="tableCell">
                    <span className={`movement_type ${row.movement_type}`}>{row.movement_type}</span>
                  </TableCell>
                  <TableCell className="tableCell">{formatCurrency(row.fee_charged)}</TableCell>
                  <TableCell className="tableCell" align="left">{row.order_id}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='table'
                    labelDisplayedRows={({ from, to, count }) => ` ${from} a ${to} de  ${count} transações`}
                />
            </Paper>
        </div>
    );
}
