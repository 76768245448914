import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PixIcon from '@mui/icons-material/Pix';
import { useContext } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { Link } from "react-router-dom";
import logo from '../../../public/cashpaylogoyellow.png';
import logomobile from '../../../public/logocoloridofav.png';
import { DarkModeContext } from "../../context/darkModeContext.jsx";
import "./sidebar.scss";

const Sidebar = () => {
  const { darkMode, dispatch } = useContext(DarkModeContext);
  const { t } = useTranslation();

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      window.location.replace(
        "/",
      );
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  const getClassHome = () => {
    if (location.pathname === '/') {
      return darkMode ? 'border-l-4 !border-iziblue' : 'border-l-4 !border-izired';
    } else {
      return '';
    }
  };
  const getClassTransaction = () => {
    if (location.pathname === '/transactions') {
      return darkMode ? 'border-l-4 !border-iziblue' : 'border-l-4 !border-izired';
    } else {
      return '';
    }
  };
  const getClassPayments = () => {
    if (location.pathname === '/payments') {
      return darkMode ? 'border-l-4 !border-iziblue' : 'border-l-4 !border-izired';
    } else {
      return '';
    }
  };
  const getClassProfile = () => {
    if (location.pathname === '/profile') {
      return darkMode ? 'border-l-4 !border-iziblue' : 'border-l-4 !border-izired';
    } else {
      return '';
    }
  };
  const classHome = getClassHome();
  const classTransaction = getClassTransaction();
  const classPayments = getClassPayments();
  const classProfile = getClassProfile();
  return (
    <div className="w-2/12 sm:w-1/12 ismobile:w-2/12 sidebar min-h-screen bg-white border-r-2 border-zinc-300">
      <div className="h-16 sm:h-28 w-full border-b-2 border-zinc-300 flex items-center justify-center">
        <div className="w-11/12">
          <div className="hidden ismobile:block">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo} alt="Cashpay Logo" className="logo" />
            </Link>
          </div>
          <div className="block ismobile:hidden">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logomobile} alt="izipay Logo" className="logo" />
            </Link>
          </div>
        </div>
      </div>
      <div className="p-5">
        <div className="mt-12 items-center ismobile:items-baseline p-0 flex flex-col gap-7">
          {/* <p className="title">{t('MAIN')}</p> */}
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className={classHome + " border-l-4 border-transparent flex"}>
              <div className=" self-center ml-4">
                <DashboardIcon className={darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "} />
              </div>
              <span className='hidden ismobile:block self-center text-base mdscreen:text-xl font-semibold ml-3 text-zinc-600'>{t('Tela Inicial')}</span>
            </div>
          </Link>
          {/* <p className="title">{t('VIEWS')}</p> */}
          <Link to="/transactions" style={{ textDecoration: "none" }}>
            <div className={classTransaction + " border-l-4 border-transparent flex"}>
              <div className=" self-center ml-4">
                <CreditCardIcon className={darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "} />
              </div>
              <span className="hidden ismobile:block self-center text-base mdscreen:text-xl font-semibold ml-3 text-zinc-600">{t("Transações")}</span>
            </div>
          </Link>
          {/* <p className="title">{t('USEFUL')}</p> */}
          <Link to="/payments" style={{ textDecoration: "none" }}>
            <div className={classPayments + " border-l-4 border-transparent flex"}>
              <div className=" self-center ml-4">
                <PixIcon className={darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "} />
              </div>
              <span className="hidden ismobile:block self-center text-base mdscreen:text-xl font-semibold ml-3 text-zinc-600">{t("Funcionalidades")}</span>
            </div>
          </Link>
          {/*
          <Link to="/receipts" style={{ textDecoration: "none" }}>
            <li>
              <ReceiptIcon className="text-iziblue text-2xl " />
              <span>Receipts</span>
            </li>
          </Link>
          */}
          {/* <p className="title">{t('USER')}</p> */}
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <div className={classProfile + " border-l-4 border-transparent flex"}>
              <div className=" self-center ml-4">
                <AccountCircleOutlinedIcon className={darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "} />
              </div>
              <span className="hidden ismobile:block self-center text-base mdscreen:text-xl font-semibold ml-3 text-zinc-600">{t('Perfil')}</span>
            </div>
          </Link>

          <div className="flex border-l-4 border-transparent">
            <div className=" self-center ml-4">
              <ExitToAppIcon className={darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "} onClick={handleSignOut} />
            </div>
            <span onClick={handleSignOut} style={{ cursor: "pointer" }} className="hidden ismobile:block text-base self-center mdscreen:text-xl font-semibold ml-3 text-zinc-600">{t('Sair')}</span>
          </div>
        </div>

      </div>
      <div className="hidden sm:flex bottom justify-center mt-5 items-center gap-1">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        >

        </div>
      </div>
    </div>
  );
};

export default Sidebar;