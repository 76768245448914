import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import './admin.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LanguageIcon from '@mui/icons-material/Language';
import { useMediaQuery } from 'react-responsive';
import { getAllDocuments } from '../../services/TransactionsService';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
const queryClient = new QueryClient();
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}));
const labelFontSize = "1.05rem";
const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: labelFontSize,
                    fontWeight: 700
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& > fieldset > legend": {
                        fontSize: `calc(0.75 * ${labelFontSize})`
                    }
                }
            }
        }
    }
});
const Profile = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(true);
    if (isMobile) {
        import("./adminMobile.scss");
    } else {
        import("./admin.scss");
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getAllDocuments();
                setResponseData(response);
                console.log(responseData)
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return <div className="text-center mt-24"><CircularProgress color="inherit" /></div>;
    }
    const convertTimezone = (date, fromTimezone, toTimezone) => {
        const convertedDate = dayjs(date).tz(fromTimezone).tz(toTimezone);

        console.log(dayjs(responseData.created_at).format('DD/MM/YYYY HH:mm:ss'))
        return '';
    };

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <div className="profile">
                    <Sidebar />
                    <div className='w-10/12 sm:w-full'>
                        <Navbar />
                        <div className="h-[calc(100%-112px)] flex flex-col ">
                            <div className="flex items-center w-full place-content-center my-8">
                                <div className="mx-auto">
                                    <div className="flex flex-col gap-2 w-full">
                                        <div className='flex gap-20 self-center'>
                                            <div className='text-center'><span className=' font-semibold text-xl '>Status:</span><span className='text-xl'>{responseData.status}</span></div>
                                            <div className='text-center'><span className=' font-semibold text-xl '>User ID:</span><span className='text-xl'>{responseData.user_id}</span></div>
                                            <div className='text-center'><span className=' font-semibold text-xl '>Data Criação: </span><span className='text-xl'>{dayjs(responseData.created_at).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                                        </div>
                                        <div className='flex gap-20 justify-between'>
                                            <div><span className=' font-semibold text-xl'>{t('Nome: ')}</span><span className='text-xl'>{responseData.name}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Data de Nascimento: ')}</span><span className='text-xl'>{dayjs(responseData.birth_date).format('DD/MM/YYYY')}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('CPF: ')}</span><span className='text-xl'>{responseData.cpf}</span></div>
                                        </div>
                                        <div className='flex gap-20 justify-between'>
                                            <div><span className=' font-semibold text-xl'>{t('Email: ')}</span><span className='text-xl'>{responseData.email}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Telefone: ')}</span><span className='text-xl'>{responseData.phone}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Número(?) ')}</span><span className='text-xl'>{responseData.number}</span></div>
                                        </div>
                                        <div className='flex gap-20 justify-between'>
                                            <div><span className=' font-semibold text-xl'>{t('Rua: ')}</span><span className='text-xl'>{responseData.street}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Número: ')}</span><span className='text-xl'>{responseData.number}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Cidade: ')}</span><span className='text-xl'>{responseData.city}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Estado: ')}</span><span className='text-xl'>{responseData.state}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('CEP: ')}</span><span className='text-xl'>{responseData.zip_code}</span></div>
                                        </div>
                                        <div className='flex gap-20 justify-between'>
                                            <div><span className=' font-semibold text-xl'>{t('Tipo do Documento: ')}</span><span className='text-xl'>{responseData.document_type}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Número do Documento: ')}</span><span className='text-xl'>{responseData.document_number}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Autoridade Emissora: ')}</span><span className='text-xl'>{responseData.issuing_authority}</span></div>
                                            <div><span className=' font-semibold text-xl'>{t('Emissão: ')}</span><span className='text-xl'>{dayjs(responseData.issue_date).format('DD/MM/YYYY')}</span></div>
                                        </div>
                                        <div className='flex'>
                                            <div className='flex flex-col w-1/2 items-center'><span className='text-3xl font-semibold'>Foto do Documento</span><img className='w-2/3' src={responseData.document_photo_content} alt="Base64 PNG Image" /></div>
                                            <div className='flex flex-col w-1/2 items-center'><span className='text-3xl font-semibold'>Usuário com o Documento</span><img className='w-2/3' src={responseData.user_with_document_photo_content} alt="Base64 PNG Image" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default Profile;
