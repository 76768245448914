import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useRef, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import DrawSVG from "react-svg-drawing";
import ReactTyped from "react-typed";
import logo from '../../../public/cashpaylogo.png';
import { authenticate } from "../../services/TransactionsService";
import "./loginexb.scss";
const LoginScreen = () => {
  //User State
  const [username, setUsername] = useState("");
  //Password State
  const [password, setPassword] = useState("");
  //Error State
  const [error, setError] = useState("");
  //Ref to see if the user is empty
  const userref = useRef(null);
  //Ref to see if the password is empty
  const passwordref = useRef(null);
  //Password Visibility Type
  const [type, setType] = useState("password");
  //Password Visibility Icon
  const [isActive, setIsActive] = useState(false);
  //Backdrop State
  const [open, setOpen] = useState(false);
  //Password Visibility
  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      if (username.trim().length == 0) {
        if (userref.current) {
          userref.current.focus();
          return;
        }
      }
      if (password.trim().length == 0) {
        if (passwordref.current) {
          passwordref.current.focus();
          return;
        }
      }
      //Activate Backdrop
      setOpen(true);
      // Call the authenticate function with the user credentials
      const token = await authenticate(username.trim(), password.trim());
      console.log(token);
      // Check if response is successful
      if (token) {
        // console.log("Login successful!");
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        // localStorage.setItem("loggedIn", "true");
        localStorage.setItem("token", token);
        window.location.reload(); // Refresh the page
      } else {
        // If login failed, show an error
        localStorage.clear();
        setError("Invalid username or password");
        // console.log("Login failed!");
      }
    } catch (err) {
      // If there's an error with the request, show an error
      console.log("🚀 ~ handleLogin ~ err:", err);
      setError("Login Inválido.");
      setTimeout(() => {
        setError("");
      }, 4000);
      setOpen(false);
      // If login failed, show an error
      localStorage.clear();
      /* window.location.reload(); */ // Refresh the page
    }
  };

  // Input Border Anmiation
  const [isFocuseduser, setIsFocuseduser] = useState(false);
  const [isFocusedpassword, setIsFocusedpassword] = useState(false);
  const handleFocususer = () => {
    setTimeout(() => {
      setIsFocuseduser(true);
    }, 301);
  };
  const handleBluruser = (event) => {
    event.preventDefault();

    if (username.trim().length !== 0) {
      // console.log('input value is NOT empty');
    } else {
      setIsFocuseduser(false);
    }
  };
  const handleFocuspassword = () => {
    setTimeout(() => {
      setIsFocusedpassword(true);
    }, 301);
  };
  const handleBlurpassword = (event) => {
    event.preventDefault();

    if (password.trim().length !== 0) {
      // console.log('input value is NOT empty');
    } else {
      setIsFocusedpassword(false);
    }
  };

  return (
    <div className="h-screen flex content-center">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="w-3/5 hidden sm:flex  self-center bgimage h-full ">
        <div className="flex flex-center flex-col self-center mx-auto w-full h-full pt-16 pl-16 pb-16 relative">
          <div className="h-full flex flex-col justify-center -mt-4">
            <ReactTyped
              className="text-6xl font-bold text-white"
              loop
              typeSpeed={100}
              backSpeed={60}
              strings={["Inovadora", "Eficiente", "Segura", "Integrada"]}
              smartBackspace
              shuffle={false}
              backDelay={1500}
              fadeOut={false}
              fadeOutDelay={100}
              loopCount={0}
              showCursor
              cursorChar="|"
            />
            <span className="flex w-8/12 mt-6 font-semibold text-2xl leading-normal text-white">
              Com segurança de ponta e uma abordagem centrada no usuário,
              capacitamos você a conduzir seus negócios de forma ágil e
              confiável. Descubra a evolução dos pagamentos conosco, onde a
              tecnologia encontra a praticidade.
            </span>
          </div>
          <div className="absolute bottom-5">
            {/* <a href="https://www.izipaynow.com" target="_blank" className="flex w-8/12 font-semibold leading-normal text-white text-nowrap">
                            www.izipaynow.com
                        </a> */}
            <a
              href="https://www.instagram.com/cashpayoficial/"
              target="_blank"
              className="flex w-8/12 font-semibold leading-normal text-white text-nowrap"
            >
              @cashpayoficial
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5521995010930&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20quero%20saber%20mais%20sobre%20a%20Cash%20Pay"
              className="flex w-8/12 font-semibold leading-normal text-white text-nowrap"
              target="_blank"
            >
              +55 21 99501-0930
            </a>
          </div>

          {/* <div>
                        <ReactTyped
                            strings={["Uma fintech inovadora", "Uma fintech eficiente", "Uma fintech segura", "Uma fintech integrada"]}
                            typeSpeed={50}
                            backSpeed={50}
                            backDelay={1}
                            loop
                            smartBackspace
                        />
                    </div> */}
        </div>
      </div>
      <div className="w-full sm:w-2/5 flex flex-col self-center h-full my-8">
        {/* {error && } */}

        <div className="w-10/12 sm:w-7/12 mx-auto mt-24 sm:mt-40">
          <div className="flex justify-center mb-20">
            <div className="flex relative items-center">
              <img src={logo} alt="cashpay Logo" className="logo" />

            </div>
          </div>
          <div className="mb-4">
            <h1 className="text-left text-3xl font-semibold"> Fazer login</h1>
          </div>
          {/* <div className="flex gap-8 border-b border-gray-light">
                        <a className="font-semibold py-2 border-b-2 border-transparent hover:border-gray-main" href="">Produção</a>
                        <a className="font-semibold py-2 border-b-2 border-transparent hover:border-gray-main" href="">Sandbox</a>
                    </div> */}
          <div className="error font-semibold text-base">{error}</div>
          <form
            className="flex flex-col text-center mt-5"
            onSubmit={handleLogin}
          >
            <div className="w-full relative ">
              <input
                className={
                  isFocuseduser
                    ? "animatekeep cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                    : "cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                }
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onFocus={handleFocususer}
                onBlur={handleBluruser}
                ref={userref}
              />
              <span className="focus-border">
                <i></i>
              </span>
              <div className="top-0.5 left-3 absolute bg-transparent">
                <span className="text-xs text-gray-300">User</span>
              </div>
            </div>
            <div className="w-full mt-2 relative">
              <input
                className={
                  isFocusedpassword
                    ? "cursor-text animatekeep animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                    : "cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                }
                type={type}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={handleFocuspassword}
                onBlur={handleBlurpassword}
                ref={passwordref}
              />
              <span
                className="bottom-4 right-2.5 absolute"
                onClick={handleToggle}
              >
                <div onClick={() => setIsActive(!isActive)}>
                  {isActive ? (
                    <MdVisibility
                      color="#183350"
                      className="cursor-pointer"
                      size={20}
                    />
                  ) : (
                    <MdVisibilityOff
                      color="#183350"
                      className="cursor-pointer"
                      size={20}
                    />
                  )}
                </div>
              </span>
              <div className="top-0.5 left-3 absolute bg-transparent">
                <span className="text-xs text-gray-300">Password</span>
              </div>
            </div>

            <div className="text-center mt-6">
              <button
                className="btn-primary-large bg-blue-950 border-transparent text-white bg-primary-600 active:bg-primary-700 hover:bg-primary-700 focus:bg-primary-700 active:bg-primary-800 focus:shadow-outline-primary text-sm leading-5 px-4 py-2 font-medium rounded-md inline-flex items-center justify-center border focus:outline-none transition duration-150 ease-in-out"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
