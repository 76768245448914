import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const isAdmin = localStorage.getItem("is_admin");
    var isTrueSet = (isAdmin === 'true'); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isTrueSet ? <Outlet /> : <Navigate to="/" />;
}
export default PrivateRoute;