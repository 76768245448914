import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { updateUserInfo } from "../../services/TransactionsService";
import "./profile.scss";
const queryClient = new QueryClient();
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});
const Profile = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  if (isMobile) {
    import("./profileMobile.scss");
  } else {
    import("./profile.scss");
  }
  const [userInfo, setUserInfo] = useState({
    accountName: localStorage.getItem("account_name"),
    Email: localStorage.getItem("2faemail"),
    virtualAccount: localStorage.getItem("virtual_account"),
    username: localStorage.getItem("username"),
    password: localStorage.getItem("password"),
    apiToken: localStorage.getItem("token"),
    webhookUrl: localStorage.getItem("webhook_url"),
  });
  const dictInfo = {
    accountNameInfo: "CASH PAY SOLUCOES FINANCEIRAS LTDA",
    agency: "0002",
    account: "1787626-0",
    bank: "401",
    // ispb: "13203354",
  };
  const dictTooltip = {
    agency:
      "Se utilizado para  receber TED, envie comprovante e avise o atendimento da Cashpay.",
    account:
      "Se utilizado para receber TED, envie comprovante e avise o atendimento da Cashpay.",
    bank: "Código bancário do liquidante Cashpay.",
  };
  const [editMode, setEditMode] = useState({
    virtualAccount: false,
    webhookUrl: false,
    apiToken: false,
    username: false,
    password: false,
    accountName: false,
    Email: false,
  });
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const handleEdit = (field) => {
    const element = document.getElementById(field);
    if (element) {
      element.focus();
    }
    setVisible({ ...visibleField, [field]: true });
    setEditMode({ ...editMode, [field]: true });
  };

  const handleChange = (e, field) => {
    setUserInfo({ ...userInfo, [field]: e.target.value });
  };

  const handleSave = async (field) => {
    const updatedUserInfo = {
      account_name: userInfo?.accountName,
      dashboard_password: userInfo?.password,
      webhook_url: userInfo?.webhookUrl,
    };

    try {
      await updateUserInfo(updatedUserInfo);
      console.log(`Saved ${field}:`, userInfo[field]);
    } catch (error) {
      console.error("Error updating user info:", error);
      // Handle error appropriately
    }

    setEditMode({ ...editMode, [field]: false });
  };

  const technologyFields = {
    virtualAccount: false,
    webhookUrl: true,
    apiToken: true,
    username: false,
    password: false,
    accountName: false,
    Email: false,
  };
  const profileFields = {
    virtualAccount: true,
    webhookUrl: false,
    apiToken: false,
    username: true,
    password: true,
    accountName: true,
    Email: true,
  };
  const editableFields = {
    virtualAccount: false,
    webhookUrl: true,
    apiToken: false,
    username: false,
    password: true,
    accountName: true,
    Email: false,
  };
  const hiddenFields = {
    virtualAccount: false,
    webhookUrl: false,
    apiToken: true,
    username: false,
    password: true,
    accountName: false,
    Email: false,
  };
  const [visibleField, setVisible] = useState({
    virtualAccount: false,
    webhookUrl: true,
    apiToken: false,
    username: true,
    password: false,
    accountName: true,
    Email: true,
  });
  const [displayText, setDisplayText] = useState("∗∗∗∗∗");
  const handleClick = () => {
    /* if (metrics?.balance == undefined) {
      console.log("undefined")
    }
    if (metrics?.balance != undefined) {
      console.log("----------------------------defined----------------------------------")
    } */
    const newText =
      displayText === "∗∗∗∗∗" ? formatToBRLCurrency(balance) : "∗∗∗∗∗";
    setDisplayText(newText);
  };
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState("");
  function handleClickShowPassword(field) {
    visibleField[field]
      ? setVisible({ ...visibleField, [field]: false })
      : setVisible({ ...visibleField, [field]: true });
  }
  const [activeButton, setActiveButton] = useState(1);
  const swiperRef = useRef(null);
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    swiperRef.current.swiper.slideTo(buttonId - 1);
    /* swiperRef.current.swiper.slideTo(buttonId); */
  };
  const activediv = { 1: "first", 2: "second", 3: "third" };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="profile">
          <Sidebar />
          <div className="w-10/12 sm:w-full">
            <Navbar />
            <div className="h-[calc(100%-112px)] flex flex-col">
              <div className="w-full sm:w-fit relative pb-1 mx-auto">
                <div className="flex px-4 pt-4 w-full justify-center mx-auto sm:w-fit">
                  <div
                    className={
                      "roundborder relative " + activediv[activeButton]
                    }
                  ></div>
                  <div
                    className={
                      activeButton === 1
                        ? "border-b-2 text-xl font-bold border-izired pl-8 pr-8 sm:pl-4 sm:pr-4 animatefont"
                        : "border-b-2 border-iziblue text-xl pl-8 pr-8 sm:pl-4 sm:pr-4 animatefont"
                    }
                  >
                    {" "}
                    <button onClick={() => handleButtonClick(1)}>
                      <div className="sm:hidden">
                        <AccountBoxIcon
                          style={
                            activeButton === 1
                              ? {
                                  fontSize: "35px",
                                  verticalAlign: "center",
                                  color: "#ca4838",
                                }
                              : {
                                  fontSize: "35px",
                                  verticalAlign: "center",
                                  color: "#283650",
                                }
                          }
                        />
                      </div>
                      <span className="hidden sm:block">{t("Meu Perfil")}</span>
                    </button>
                  </div>
                  <div
                    className={
                      activeButton === 2
                        ? "border-b-2 text-xl font-bold border-izired pl-8 pr-8 sm:pl-4 sm:pr-4 animatefont"
                        : "border-b-2 border-iziblue text-xl pl-8 pr-8 sm:pl-4 sm:pr-4 animatefont"
                    }
                  >
                    {" "}
                    <button onClick={() => handleButtonClick(2)}>
                      <div className="sm:hidden">
                        <AccountBalanceIcon
                          style={
                            activeButton === 2
                              ? {
                                  fontSize: "35px",
                                  verticalAlign: "center",
                                  color: "#ca4838",
                                }
                              : {
                                  fontSize: "35px",
                                  verticalAlign: "center",
                                  color: "#283650",
                                }
                          }
                        />
                      </div>
                      <span className="hidden sm:block">
                        {t("Detalhes da Conta")}
                      </span>
                    </button>
                  </div>
                  <div
                    className={
                      activeButton === 3
                        ? "border-b-2 text-xl font-bold border-izired pl-8 pr-8 sm:pl-4 sm:pr-4 animatefont"
                        : "border-b-2 border-iziblue text-xl pl-8 pr-8 sm:pl-4 sm:pr-4 animatefont"
                    }
                  >
                    {" "}
                    <button onClick={() => handleButtonClick(3)}>
                      <div className="sm:hidden">
                        <LanguageIcon
                          style={
                            activeButton === 3
                              ? {
                                  fontSize: "35px",
                                  verticalAlign: "center",
                                  color: "#ca4838",
                                }
                              : {
                                  fontSize: "35px",
                                  verticalAlign: "center",
                                  color: "#283650",
                                }
                          }
                        />
                      </div>
                      <span className="hidden sm:block">
                        {t("Tecnologias")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="h-full" style={{ maxWidth: "1645.47px" }}>
                <Swiper
                  navigation={false}
                  ref={swiperRef}
                  className="h-full"
                  allowTouchMove={false}
                >
                  <SwiperSlide>
                    <Paper className="infoPaper p-8 w-full sm:w-1/2 mx-auto mt-10">
                      <div className="flex flex-col mx-auto">
                        <div className="w-fit mx-auto rounded titlecard">
                          <span className="text-2xl font-semibold shadowtext p-5">
                            {t("Meu Perfil")}
                          </span>
                        </div>
                        {Object.entries(userInfo).map(
                          ([key, value]) =>
                            profileFields[key] && (
                              <div key={key} className="profileField h-20">
                                {hiddenFields[key] && (
                                  <TextField
                                    id={key}
                                    label={t(key)}
                                    value={value}
                                    onChange={(e) => handleChange(e, key)}
                                    variant="outlined"
                                    fullWidth
                                    className={
                                      !editMode[key] ? "readOnlyField" : ""
                                    }
                                    type={
                                      visibleField[key] ? "text" : "password"
                                    }
                                    inputProps={{
                                      style: {
                                        padding: 13,
                                      },
                                    }}
                                    InputProps={{
                                      readOnly: !editMode[key],
                                      endAdornment: (
                                        <InputAdornment
                                          key="toggleVisibility"
                                          position="end"
                                        >
                                          <IconButton
                                            onClick={() =>
                                              handleClickShowPassword(key)
                                            }
                                            aria-label={key}
                                            edge="end"
                                          >
                                            {visibleField[key] ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {!hiddenFields[key] && (
                                  <TextField
                                    inputProps={{
                                      style: {
                                        padding: 13,
                                      },
                                    }}
                                    id={key}
                                    inputRef={inputRef}
                                    label={
                                      key == "Email" ? (
                                        <LightTooltip
                                          title="Usado para saques com autenticação de segurança via PIN."
                                          TransitionComponent={Zoom}
                                          placement="right"
                                          arrow
                                        >
                                          <div className="flex gap-1 items-center justify-center">
                                            {t(key)}
                                            <InfoOutlinedIcon
                                              style={{
                                                fontSize: "large",
                                                verticalAlign: "center",
                                              }}
                                            />
                                          </div>
                                        </LightTooltip>
                                      ) : (
                                        t(key)
                                      )
                                    }
                                    value={value}
                                    onChange={(e) => handleChange(e, key)}
                                    InputProps={{ readOnly: !editMode[key] }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={
                                      !editMode[key] ? "readOnlyField" : ""
                                    }
                                  />
                                )}
                                <div className="buttonContainer">
                                  {editableFields[key] &&
                                    (!editMode[key] ? (
                                      <Button
                                        sx={{
                                          minHeight: 0,
                                          minWidth: 0,
                                          padding: 0,
                                        }}
                                        onClick={() => handleEdit(key)}
                                      >
                                        <EditIcon
                                          style={{
                                            fontSize: "large",
                                            verticalAlign: "center",
                                          }}
                                        />
                                      </Button>
                                    ) : (
                                      <Button
                                        sx={{
                                          minHeight: 0,
                                          minWidth: 0,
                                          padding: 0,
                                        }}
                                        onClick={() => handleSave(key)}
                                      >
                                        <CheckIcon
                                          style={{
                                            fontSize: "large",
                                            verticalAlign: "center",
                                          }}
                                        />
                                      </Button>
                                    ))}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </Paper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Paper className="infoPaper p-8 w-full sm:w-1/2 mx-auto mt-10">
                      <div className="flex flex-col mx-auto">
                        <div className="w-fit mx-auto rounded titlecard">
                          <span className="text-2xl font-semibold shadowtext p-5">
                            {t("Detalhes da Conta")}
                          </span>
                        </div>
                        {Object.entries(dictInfo).map(([key, value]) => (
                          <div key={key} className="profileField h-20">
                            <TextField
                              inputProps={{
                                style: {
                                  padding: 13,
                                },
                              }}
                              id={key}
                              inputRef={inputRef}
                              label={
                                key != "accountNameInfo" && key != "ispb" ? (
                                  <LightTooltip
                                    title={dictTooltip[key]}
                                    TransitionComponent={Zoom}
                                    placement="right"
                                    arrow
                                  >
                                    <div className="flex gap-1 items-center justify-center">
                                      {t(key)}
                                      <InfoOutlinedIcon
                                        style={{
                                          fontSize: "large",
                                          verticalAlign: "center",
                                        }}
                                      />
                                    </div>
                                  </LightTooltip>
                                ) : (
                                  t(key)
                                )
                              }
                              value={value}
                              onChange={(e) => handleChange(e, key)}
                              InputProps={{ readOnly: !editMode[key] }}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              className={!editMode[key] ? "readOnlyField" : ""}
                            />
                          </div>
                        ))}
                      </div>
                    </Paper>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Paper className="infoPaper p-8 w-full sm:w-1/2 mx-auto mt-10">
                      <div className="flex flex-col mx-auto">
                        <div className="w-fit mx-auto rounded titlecard">
                          <span className="text-2xl font-semibold shadowtext p-5">
                            {t("Tecnologias")}
                          </span>
                        </div>
                        {Object.entries(userInfo).map(
                          ([key, value]) =>
                            technologyFields[key] && (
                              <div key={key} className="profileField h-20">
                                {hiddenFields[key] && (
                                  <TextField
                                    id={key}
                                    label={t(key)}
                                    value={value}
                                    onChange={(e) => handleChange(e, key)}
                                    variant="outlined"
                                    fullWidth
                                    className={
                                      !editMode[key] ? "readOnlyField" : ""
                                    }
                                    type={
                                      visibleField[key] ? "text" : "password"
                                    }
                                    inputProps={{
                                      style: {
                                        padding: 13,
                                      },
                                    }}
                                    InputProps={{
                                      readOnly: !editMode[key],
                                      endAdornment: (
                                        <InputAdornment
                                          key="toggleVisibility"
                                          position="end"
                                        >
                                          <IconButton
                                            onClick={() =>
                                              handleClickShowPassword(key)
                                            }
                                            aria-label={key}
                                            edge="end"
                                          >
                                            {visibleField[key] ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {!hiddenFields[key] && (
                                  <TextField
                                    inputProps={{
                                      style: {
                                        padding: 13,
                                      },
                                    }}
                                    id={key}
                                    inputRef={inputRef}
                                    label={t(key)}
                                    value={value}
                                    onChange={(e) => handleChange(e, key)}
                                    InputProps={{ readOnly: !editMode[key] }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={
                                      !editMode[key] ? "readOnlyField" : ""
                                    }
                                  />
                                )}
                                <div className="buttonContainer">
                                  {editableFields[key] &&
                                    (!editMode[key] ? (
                                      <Button
                                        sx={{
                                          minHeight: 0,
                                          minWidth: 0,
                                          padding: 0,
                                        }}
                                        onClick={() => handleEdit(key)}
                                      >
                                        <EditIcon
                                          style={{
                                            fontSize: "large",
                                            verticalAlign: "center",
                                          }}
                                        />
                                      </Button>
                                    ) : (
                                      <Button
                                        sx={{
                                          minHeight: 0,
                                          minWidth: 0,
                                          padding: 0,
                                        }}
                                        onClick={() => handleSave(key)}
                                      >
                                        <CheckIcon
                                          style={{
                                            fontSize: "large",
                                            verticalAlign: "center",
                                          }}
                                        />
                                      </Button>
                                    ))}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </Paper>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default Profile;
