import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { Box, Divider, Menu, MenuItem, styled } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FilterDataContext } from "../../context/filterDataContext";
import { useContext, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";

const FilterMetrics = ({ anchorEl, open, onClose }) => {
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [maxDate, setMaxDate] = useState(dayjs());
    const { setFilterData } = useContext(FilterDataContext);

    const CustomStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
        '.MuiPickersToolbar-root': {
            display: 'none',
        },
        '.MuiDialogActions-root': {
            display: 'none',
        },
        '.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#283650',
        },
        '.MuiPickersDay-root.Mui-selected:hover': {
            backgroundColor: '#ca4838',
        },
        '.MuiPickersCalendarHeader-label': {
            textTransform: 'capitalize'
        }
    }));

    const handleFromDate = (date) => {
        setStartDate(date)
        setEndDate(null)
        setMaxDate(handleMaxDate(date))
    };

    const handleToDate = (date) => {
        setEndDate(date);
    };

    const handleMaxDate = (date) => {
        const daysAfter = date.add(7, 'day');

        if (daysAfter.isAfter(dayjs())) {
            return dayjs();
        }

        return daysAfter;
    }

    const handleFilterData = () => {
        if (startDate && endDate) {
            setFilterData({
                fromDate: startDate.format('YYYY-MM-DD'),
                toDate: endDate.format('YYYY-MM-DD')
            });
        }
    }

    const { t, i18n } = useTranslation();

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}>
            <MenuItem>
                <Tabs orientation="vertical">
                    <TabList>
                        <Tab>{t('Data')}</Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <Box className="px-5 py-3">
                            <Box>{t('Selecione um intervalo de no máximo 7 dias')}.</Box>
                            <Box display={'flex'} className="py-5">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'pt-BR' ? "pt-br" : "en"}>
                                    <CustomStaticDatePicker value={startDate} onChange={handleFromDate} disableFuture />
                                </LocalizationProvider>
                                <Divider orientation="vertical" />
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'pt-BR' ? "pt-br" : "en"}>
                                    <CustomStaticDatePicker minDate={startDate} value={endDate} maxDate={maxDate} onChange={handleToDate} disableFuture />
                                </LocalizationProvider>
                            </Box>
                            <Box display={'flex'} justifyContent={'end'}>
                                <div onClick={handleFilterData} className="text-center w-3/12 cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired">{t('Aplicar Filtro')}</div>
                            </Box>
                        </Box>
                    </TabPanel>
                </Tabs>
            </MenuItem>
        </Menu>
    )
}

export default FilterMetrics;