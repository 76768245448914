import "./navbar.scss";
import React, { useState, useContext, useEffect } from "react";
import { DarkModeContext } from "../../context/darkModeContext.jsx";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useLocation } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import i18n from "i18next";
import Flag from "../flags/Flags";
import BRFlag from "../../../public/flags/br.svg";
import USFlag from "../../../public/flags/us.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useQuery } from "react-query";
import { getBalance } from "../../services/TransactionsService";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterMetrics from "../filterMetrics/FilterMetrics.jsx";

const Navbar = ({ onFilterData }) => {
  const [displayText, setDisplayText] = useState("∗∗∗∗∗");
  const [balanceon, setBalanceon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { data: metrics } = useQuery({
    queryKey: ["balance"],
    queryFn: () => {
      setBalanceon(false);
      return getBalance();
    },
    onSuccess: () => setBalanceon(true),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 1,
    refetchIntervalInBackground: false,
  });
  const { darkMode, dispatch } = useContext(DarkModeContext);
  const { t } = useTranslation();
  const location = useLocation();

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage, (err, t) => {
      if (err)
        return console.log("Something went wrong loading language:", err);
      console.log("Language changed to:", newLanguage);
    });
  };

  const handleAnchorEL = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      window.location.replace("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Check if the current page is "/transactions"
  const isReceiptsPage = location.pathname === "/receipts";

  // Check if the current page is "/"
  const isHome = location.pathname === "/";

  const formatToBRLCurrency = (rawValue) => {
    const value = parseFloat(rawValue);
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };
  useEffect(() => {
    if (metrics) {
      setBalanceon(true);
    }

    if (displayText === "∗∗∗∗∗") {
      return;
    }

    if (metrics) {
      setDisplayText(
        formatToBRLCurrency(
          metrics?.accounts[accounts.length - 1].total_balance
        )
      );
    }
  }, [metrics]);
  const handleClick = () => {
    const newText =
      displayText === "∗∗∗∗∗"
        ? formatToBRLCurrency(
            metrics?.accounts[metrics?.accounts.length - 1].total_balance
          )
        : "∗∗∗∗∗";
    setDisplayText(newText);
  };

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="w-full flex flex-col">
      <div className="h-16 sm:h-28 w-full border-b-2 border-zinc-300 flex items-center">
        <div className=" justify-between flex gap-1 sm:gap-5 items-center px-1 sm:px-10 w-full content-between">
          <div className="text-left flex flex-col items-start shadow-none sm:shadow-[0px_0px_15px_0px_rgba(0,0,0,0.36)] border-t-0 border-l-0 sm:border-t-2 sm:border-l-2 p-2 rounded-lg w-5/12 sm:1/2">
            <div className={balanceon ? "" : "hidden"}>
              <div className="flex w-full gap-5 self-center">
                <span className="font-medium text-base sm:text-xl">
                  {t("Saldo")}
                </span>
                <div className="block sm:hidden" onClick={handleClick}>
                  <div
                    className="block sm:hidden  self-center"
                    onClick={() => setIsActive(!isActive)}
                  >
                    {isActive ? (
                      <MdVisibility
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    ) : (
                      <MdVisibilityOff
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full">
                <div
                  className="hidden sm:block self-center"
                  onClick={handleClick}
                >
                  <div
                    className="hidden self-center sm:block"
                    onClick={() => setIsActive(!isActive)}
                  >
                    {isActive ? (
                      <MdVisibility
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    ) : (
                      <MdVisibilityOff
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={
                    balanceon
                      ? "font-bold text-base sm:text-xl text-nowrap"
                      : "hidden font-bold text-base sm:text-xl text-nowrap"
                  }
                >
                  {/* isNaN(displayText) ? "-" : */ displayText}
                </div>
              </div>
            </div>
            <div className={balanceon ? "hidden" : "text-center w-full"}>
              <CircularProgress color="inherit" />
            </div>
          </div>
          {isHome && (
            <>
              <div
                onClick={handleAnchorEL}
                className="cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired"
              >
                <div className="flex gap-2 text-xl sm:text-base ismobile:text-xl">
                  <span className="hidden sm:block">{t("Filtros")}</span>
                  <div className="self-center">
                    <FilterAltIcon />
                  </div>
                </div>
              </div>
              <FilterMetrics
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              />
            </>
          )}
          <div className="flex gap-2 sm:gap-10 items-center justify-end w-7/12 sm:1/2">
            <Link to="/payments" style={{ textDecoration: "none" }}>
              <div className="cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired">
                <div className="flex gap-2 text-xl sm:text-base ismobile:text-xl">
                  <span className="hidden sm:block">
                    {t("Movimentar sua conta")}
                  </span>
                  <div className="self-center">
                    <RiMoneyDollarCircleLine />
                  </div>
                </div>
              </div>
            </Link>
            <div className="hidden sm:flex gap-2">
              <div>
                <Button
                  endIcon={Flag(USFlag)}
                  onClick={() => changeLanguage("en")}
                  className="language-button"
                />
              </div>
              <div>
                <Button
                  endIcon={Flag(BRFlag)}
                  onClick={() => changeLanguage("pt-BR")}
                  className="language-button"
                />
              </div>
            </div>
            <div className="flex gap-2">
              <div>
                <Link to="/profile" style={{ textDecoration: "none" }}>
                  <AccountCircleOutlinedIcon
                    className={
                      darkMode
                        ? "text-izired text-2xl "
                        : "text-iziblue text-2xl "
                    }
                  />
                </Link>
              </div>
              <div>
                <ExitToAppIcon
                  className={
                    darkMode
                      ? "text-izired text-2xl "
                      : "text-iziblue text-2xl "
                  }
                  style={{ cursor: "pointer" }}
                  onClick={handleSignOut}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isReceiptsPage && (
        <>
          <TextField id="outlined-basic" label="Order ID" variant="outlined" />
          <div className="button">
            <Button variant="outlined">Outlined</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
